<template>
  <div class="page-content">
    <h2>Contact me</h2>
    <p>For inquiries, please contact me on my LinkedIn profile:</p>
    <a href="https://www.linkedin.com/in/emil-halvorsen-2380b3103" target="_blank" class="linkedin-link">My LinkedIn Profile</a>
  </div>
</template>

<script>
export default {
  name: 'ContactLinkedIn',
};
</script>

<style scoped>
.page-content {
  margin-bottom: 2rem;
}

.linkedin-link {
  color: #0e76a8;
  text-decoration: none;
}
</style>